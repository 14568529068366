import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithPopover, Icon } from '@seeqdev/qomponents';
import { sqImpactReportStore, sqWorkbenchStore } from '@/core/core.stores';
import { useLocation, useParams } from 'react-router-dom';
import { ImpactReportEditModal } from '@/impactReports/ImpactReportEditModal.page';
import { useAsyncEffect } from 'rooks';
import {
  setDisplayValueCaptureEditModal,
  setForceTableReloadFlag,
  setUpdateNominations,
} from '@/impactReports/impactReport.actions';
import { AnyProperty } from '@/utilities.types';
import { ImpactReportDetailModal } from '@/impactReports/ImpactReportDetailModal.page';
import {
  FlaggedItem,
  ImpactReportUseCase,
  NOMINATE_IMPACT_QUERY_PARAM,
  OPEN_CREATE_MODAL_QUERY_PARAM,
  TRACK_IMPACT,
} from '@/impactReports/impactReport.types';
import { doTrack } from '@/track/track.service';
import {
  getImpactReportForWorksheet,
  getNominatedWorksheets,
  goToWorksheetAndMakeImpactReport,
  nominateWorksheetToBecomeImpactReport,
  removeNomination,
} from '@/impactReports/impactReport.utilities';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { openLinkBasedOnUserPreferences } from '@/core/utilities';

export const ImpactReportMenu: React.FunctionComponent = () => {
  const location = useLocation();
  const { workbookId, worksheetId } = useParams();
  const { t } = useTranslation();
  const [existingReport, setExistingReport] = useState<AnyProperty[] | undefined>(undefined);
  const displayEditModal = useFluxPath(sqImpactReportStore, () => sqImpactReportStore.displayEditModal);
  const updateNominations = useFluxPath(sqImpactReportStore, () => sqImpactReportStore.updateNominations);
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);
  const [worksheetFlagged, setWorksheetFlagged] = useState(false);
  const [flaggedItems, setFlaggedItems] = useState<FlaggedItem[] | undefined>([]);
  const preferNewTab = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.preferNewTab);
  /**
   * This useAsyncEffect listens to URL changes and either flags a worksheet to become an impact report or opens the
   * "create" modal. PAM (the Pricing And Monetization available at usage.seeq.com will trigger the ?nominateImpact
   * query to flag worksheets)
   */
  useAsyncEffect(async () => {
    flaggedItems && setWorksheetFlagged(flaggedItems.some((item) => item.worksheetId === worksheetId));

    if (location.search.includes(OPEN_CREATE_MODAL_QUERY_PARAM)) {
      setDisplayValueCaptureEditModal(true);
    }
    if (location.search.includes(NOMINATE_IMPACT_QUERY_PARAM)) {
      await nominateImpactReport(true);
    }
  }, [location]);

  /**
   * This useAsyncEffect fetches the list of worksheets that are "nominated" to be turned into impact reports.
   * We use context labels to flag worksheets, and we only show the flagged worksheets that are owned by the current
   * user.
   */
  useAsyncEffect(async () => {
    if (updateNominations) {
      setFlaggedItems(await getNominatedWorksheets());
      // update the worksheetFlagged state if the current worksheet is in the list of flagged worksheets
      if (flaggedItems && flaggedItems.length > 0) {
        setWorksheetFlagged(flaggedItems.some((item) => item.worksheetId === worksheetId));
      }
      setUpdateNominations(false);
    }
  }, [updateNominations]);

  const nominateImpactReport = async (fromQuery = false) => {
    if (!worksheetId || !workbookId) return;

    const doNominate = await nominateWorksheetToBecomeImpactReport(worksheetId, workbookId);
    if (doNominate) {
      setWorksheetFlagged(true);
      setUpdateNominations(true); // it is unlikely that a user flags one of their own worksheets but it's odd not
      // to update the list of flagged worksheets if they do
      doTrack(TRACK_IMPACT, 'Nominate Impact Report', fromQuery ? 'from Query (PAM)' : 'from Toolbar menu');
    } else {
      doTrack(TRACK_IMPACT, 'Nominate Impact Report', 'failed to nominate');
    }
  };

  /**
   * This useAsyncEffect fetches the existing impact report for the current worksheet to make sure the icon (active
   * vs non-active) stays in-sync with the current worksheet
   */
  useAsyncEffect(async () => {
    if (displayEditModal) return;
    setExistingReport(await getImpactReportForWorksheet(workbookId, worksheetId));
  }, [displayEditModal, workbookId, worksheetId]);

  const actions = [
    {
      href: '#',
      iconClass: 'fa-pencil',
      action: () => {
        // if an impact reports exists we open the detail modal, if not we open the edit modal to create a new one
        if (existingReport) {
          doTrack(TRACK_IMPACT, 'View existing Impact Report', 'from Toolbar menu');
          setDisplayDetailsModal(true);
        } else {
          setDisplayValueCaptureEditModal(true);
          doTrack(TRACK_IMPACT, 'Create new Impact Report', 'from Toolbar menu');
        }
      },
      translationKey: existingReport ? 'IMPACT_REPORT.VIEW' : 'IMPACT_REPORT.NEW',
    },
    {
      href: '#',
      iconClass: 'fa-lightbulb-dollar',
      action: () => openLinkBasedOnUserPreferences('/impact', preferNewTab),
      translationKey: 'IMPACT_REPORT.VIEW_ALL',
    },
    {
      href: '#',
      iconClass: 'fa-star',
      action: async () => await nominateImpactReport(),
      translationKey: 'IMPACT_REPORT.PROMPT_TO_CREATE',
      disabled: worksheetFlagged || existingReport || !location.pathname.includes('/worksheet'),
    },
  ];

  return (
    <>
      <div className="icon-container">
        <ButtonWithPopover
          trigger={
            <Icon
              icon="fa-lightbulb-dollar"
              extraClassNames="fa-xlg sq-navbar-default flexNoGrowNoShrink mb1"
              type="white"
              testId="valueCaptureMenu"
              tooltip={t('IMPACT_REPORT.IMPACT_REPORT')}
              tooltipPlacement="bottom"
              iconPrefix={existingReport ? 'fa-solid' : ''}
            />
          }>
          <div className="flexRowContainer mt10 sq-text impactReport gap5">
            {actions.map(({ translationKey, iconClass, action, disabled }) => (
              <div
                key={`dropdown_${translationKey}`}
                className={`sq-text dropdownEntry pl15 pr15 p5 ${disabled ? 'disabled' : ''}`}
                onClick={disabled ? () => {} : action}>
                <Icon icon={iconClass} extraClassNames="fa-fw mr5" type={disabled ? 'gray' : 'text'} />
                {t(translationKey)}
              </div>
            ))}
            {flaggedItems && flaggedItems.length > 0 && (
              <>
                <div className="popover-header">
                  <Icon icon="fa-star" extraClassNames="pr5" type="text" />
                  {t('IMPACT_REPORT.FLAGGED_WORKSHEETS')}
                </div>

                <div className="flexRowContainer pl15 pr15 gap5 pt5 pb5 max-width-300">
                  {flaggedItems.map(({ name, workbookId, worksheetId, labelId }) => (
                    <div className="flexColumnContainer flexAlignCenter" key={`dropdown_${name}`}>
                      <div
                        className="link cursorPointer fakeLink flexFill pr5"
                        onClick={() => {
                          workbookId && goToWorksheetAndMakeImpactReport(worksheetId, workbookId, preferNewTab);
                        }}>
                        {name}
                      </div>
                      <Icon
                        icon="fa-close"
                        extraClassNames="pl5 cursorPointer"
                        tooltip={t('IMPACT_REPORT.REMOVE_NOMINATION')}
                        onClick={() => {
                          const removed = labelId && removeNomination(labelId, worksheetId);
                          if (removed) setUpdateNominations(true);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </ButtonWithPopover>
        {displayDetailsModal && (
          <ImpactReportDetailModal
            closeFn={() => setDisplayDetailsModal(false)}
            details={existingReport as unknown as ImpactReportUseCase}
            editCallbackFn={() => {
              setDisplayDetailsModal(false);
              setDisplayValueCaptureEditModal(true);
            }}
          />
        )}
        {displayEditModal && (
          <ImpactReportEditModal
            existingReport={existingReport}
            closeFn={(reload = false) => {
              if (reload && window.location.pathname === '/impact') {
                setForceTableReloadFlag(true);
              }
              setDisplayValueCaptureEditModal(false);
            }}
          />
        )}
        {flaggedItems && flaggedItems.length > 0 && (
          <span className="icon-badge" id="notification-count">
            {flaggedItems.length}
          </span>
        )}
      </div>
    </>
  );
};
