import { sqContextApi, sqGraphQLApi, sqItemsApi, sqTableDefinitionsApi, sqWorkbooksApi } from '@/sdk';
import {
  setGlobalLayoutStoreOnly,
  setImpactReportCategories,
  setImpactReportShowAssetPathStoreOnly,
  setImpactReportShowSiteStoreOnly,
  setImpactReportTableId,
} from '@/impactReports/impactReport.actions';
import {
  availableStages,
  FlaggedItem,
  IMPACT_REPORT_CONTEXT_CATEGORY,
  IMPACT_REPORT_NOMINATION_LABEL,
  ImpactReportConfiguration,
  ImpactType,
  OPEN_CREATE_MODAL_QUERY_PARAM,
  stageIcons,
  Stages,
} from '@/impactReports/impactReport.types';
import { t } from 'i18next';
import { getColumnDefinitions, getImpactReportByWorksheet } from '@/impactReports/tiles/table.utilities';
import { SelectOption } from '@/formbuilder/SelectFormComponent';
import { ColDef } from '@ag-grid-community/core';
import { ScalarPropertyV1 } from 'sdk/model/ScalarPropertyV1';
import { errorToast, infoToast } from '@/utilities/toast.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { IMPACT_REPORT_CATEGORY_PREFIX, ImpactReportCategory } from './impactReport.types';
import { sqImpactReportStore, sqWorkbenchStore } from '@/core/core.stores';
import { openLinkBasedOnUserPreferences } from '@/core/utilities';
import _ from 'lodash';

export function getCustomCategoryTitle(category: ImpactReportCategory) {
  return category?.category?.name?.substring(IMPACT_REPORT_CATEGORY_PREFIX.length) ?? '';
}

export function stageDisplayStringByIndex(stageIndex: number) {
  return t(`IMPACT_REPORT.${availableStages[stageIndex]?.toUpperCase()}`);
}

export function getStageDisplayString(stage: Stages) {
  if (!stage) return '';
  if (t(`IMPACT_REPORT.${stage.toUpperCase()}`) === `IMPACT_REPORT.${stage.toUpperCase()}`) return stage;
  return t(`IMPACT_REPORT.${stage.toUpperCase()}`);
}

export function getImpactStageIcon(stage: Stages) {
  const index = availableStages.indexOf(stage);
  if (index > -1 && index <= stageIcons.length) {
    return stageIcons[availableStages.indexOf(stage)];
  } else {
    return stageIcons[0];
  }
}

export function getCategoryDisplayString(
  categoryId: string,
  categories: { value: string; label: string }[] | undefined,
  fallback = '',
) {
  const category = categories?.find((category) => category.value === categoryId);
  return category ? category.label : fallback;
}

export async function fetchCategories() {
  const { data } = await sqContextApi.findLabelCategories({});
  const categories = data?.categories?.filter((category) => category.name?.startsWith('VCC_'));
  if (!categories) return;
  const allOptions: ImpactReportCategory[] = [];
  await Promise.all(
    categories.map(async (category) => {
      const labelResponse = await sqContextApi.findLabels({ categoryId: category.id! });
      const labels = labelResponse.data.labels;
      const options = labels?.map((label) => ({ value: label.id!, label: label.name! }));
      allOptions.push({ category, options });
    }),
  );
  return allOptions;
}

export function getStageIndex(stage: Stages) {
  return availableStages.indexOf(stage.toLowerCase().trim() as Stages);
}

export function getAvailableAggregationSelectOptions(
  categories: ImpactReportCategory[] | undefined,
  showSite: boolean,
  showAssetPath: boolean,
): SelectOption<string | undefined>[] {
  const options = getColumnDefinitions(categories, showSite, showAssetPath)
    .filter((column: ColDef<string, string>) => column.context?.canAggregateBy)
    .sort((a: ColDef<string, string>, b: ColDef<string, string>) => {
      if (a.headerName! < b.headerName!) return -1;
      if (a.headerName! > b.headerName!) return 1;
      return 0;
    })
    .map((columnDef) => ({
      label: columnDef.headerName!,
      value: columnDef.field!,
    }));

  return [{ label: t('IMPACT_REPORT.NONE'), value: 'none' }, ...options];
}

/**
 * This function fetches all the categories that are available to a user to "categorize" their Impact report
 * These categories are Context Labels and we need to ensure we fetch them before we attempt to display the table as
 * we need them to properly create and map the columns for categories.
 */
export async function initializeImpactReportCategories() {
  const categories = await fetchCategories();
  setImpactReportCategories(categories);
}

/**
 * Fetches the Impact Report Table ID from the server
 * If the table does not exist, it creates it
 */
export async function getImpactReportTableId(): Promise<string | undefined> {
  const { data } = await sqItemsApi.searchItems({
    filters: [`name==${SeeqNames.ImpactReport.ImpactTableName}`],
    types: ['TableDefinition'],
  });
  const tableId = data.items && data.items[0].id;
  tableId && setImpactReportTableId(tableId);
  console.log('Value Capture Table ID:', tableId);
  await getImpactTableConfiguration(tableId!);
  return tableId;
}

/**
 * The Impact Report Table description field is used to store configuration options that apply application wide to
 * the Impact Report Table.
 * For now we save the configuration options for showing the Site and Asset Path columns in the table (and the form)
 */
export async function getImpactTableConfiguration(tableId: string) {
  const tableResponse = await sqTableDefinitionsApi.getTableDefinition({ id: tableId });
  if (!tableResponse.data.description || tableResponse.data.description === 'Impact Report Table') return;
  const { showSite, showAssetPath, globalLayout } =
    tableResponse.data.description && JSON.parse(tableResponse.data.description);
  setImpactReportShowSiteStoreOnly(showSite);
  setImpactReportShowAssetPathStoreOnly(showAssetPath);
  setGlobalLayoutStoreOnly(globalLayout ?? []);
}

export async function addOrUpdateImpactReportConfiguration(tableId: string, configuration: ImpactReportConfiguration) {
  return sqTableDefinitionsApi.updateTableDefinition(
    { name: SeeqNames.ImpactReport.ImpactTableName, description: JSON.stringify(configuration) },
    { id: tableId },
  );
}

export async function addOrUpdateImpactReport(tableId: string, impactReport: ScalarPropertyV1[]) {
  return sqGraphQLApi.graphql({
    query: 'mutation PublishEvents($events: RowEventsInput!) { publishEvent(rowEvents: $events) }',
    variables: {
      events: { tableDefinitionId: tableId, events: [{ genericEvent: { properties: impactReport } }] },
    },
  });
}

export function formatNumberAsValue(num: number): string {
  if (!Number.isFinite(num)) return '';
  if (num >= 1_000_000) {
    return `$${(num / 1_000_000).toFixed(3)}M`;
  } else if (num >= 1_000) {
    return `$${(num / 1_000).toFixed(0)}K`;
  } else {
    return `$${new Intl.NumberFormat('en-US').format(Math.round(num))}`;
  }
}

export function getImpactTypeDisplayString(impactType: ImpactType) {
  switch (impactType) {
    case 'continuous':
      return t('IMPACT_REPORT.CONTINUOUS');
    case 'oneTime':
      return t('IMPACT_REPORT.ONE_TIME');
    case 'customCalc':
      return t('IMPACT_REPORT.CUSTOM_CALC');
    default:
      return impactType;
  }
}

export function getAllSupportedBoxTypes() {
  return [
    {
      label: t('IMPACT_REPORT.TOTAL_USE_CASES'),
      value: 'useCaseCountBox',
      title: 'IMPACT_REPORT.TOTAL_USE_CASES',
      property: 'useCaseCount',
      id: 'useCaseCountBox',
    },
    {
      label: t('IMPACT_REPORT.TOTAL_IMPACT'),
      value: 'totalImpactBox',
      title: 'IMPACT_REPORT.TOTAL_IMPACT',
      property: 'overallImpact',
      id: 'totalImpactBox',
    },
    {
      label: t('IMPACT_REPORT.BIGGEST_SINGLE_IMPACT'),
      value: 'biggestImpactBox',
      title: 'IMPACT_REPORT.BIGGEST_SINGLE_IMPACT',
      property: 'biggestImpact',
      id: 'biggestImpactBox',
    },
  ];
}

export async function deleteCategory(category: ImpactReportCategory) {
  try {
    category.options &&
      (await Promise.all(
        category.options.map(async (option) => {
          try {
            await sqContextApi.deleteLabel({ labelId: option.value });
          } catch (e) {
            console.error(e);
            errorToast({ messageKey: 'Failed to delete associated label' });
          }
        }),
      ));
    await sqContextApi.deleteLabelCategory({ categoryId: category.category.id! });
  } catch (error) {
    console.error(error);
    errorToast({ messageKey: 'Failed to delete category' });
  }
  await initializeImpactReportCategories();
}

export function goToWorksheet(worksheetId: string, preferNewTab: boolean) {
  openLinkBasedOnUserPreferences(`/view/${worksheetId}`, preferNewTab);
}

export function goToWorksheetAndMakeImpactReport(worksheetId: string, workbookId: string, preferNewTab: boolean) {
  openLinkBasedOnUserPreferences(
    `workbook/${workbookId}/worksheet/${worksheetId}?${OPEN_CREATE_MODAL_QUERY_PARAM}`,
    preferNewTab,
  );
}

export async function getImpactReportForWorksheet(workbookId: string | undefined, worksheetId: string | undefined) {
  if (workbookId && worksheetId) {
    let tableId = sqImpactReportStore.tableId;
    if (!tableId) {
      tableId = await getImpactReportTableId();
    }
    if (!tableId) return;
    return await getImpactReportByWorksheet(tableId, worksheetId, workbookId);
  }

  return undefined;
}

export async function getWorksheetLabels(worksheetId: string) {
  const { data } = await sqGraphQLApi.graphql({
    query: `query Context($ids: [ContextIdInput!]!) {
      context(ids: $ids) {
        labels {
          contextId
        }
    }
  }`,
    variables: { ids: [{ itemId: worksheetId }] },
  });
  return data.data.context?.labels?.map((label: { contextId: string }) => label.contextId);
}

/**
 * This function returns the "nomination" label.
 * If it doesn't already exist and "create" is set to true it will create the category as well as the label and
 * return it.
 */
export async function getNominationLabel(doCreate = false) {
  const { data } = await sqContextApi.findLabelCategories({});
  const category = data?.categories?.find((category) => category.name === IMPACT_REPORT_CONTEXT_CATEGORY);
  let categoryId = category?.id;
  if (!category) {
    if (doCreate) {
      // create the category
      const categoryResponse = await sqContextApi.createLabelCategory({ name: IMPACT_REPORT_CONTEXT_CATEGORY });
      categoryId = categoryResponse.data.id;
    } else {
      return;
    }
  }

  const labelResponse = await sqContextApi.findLabels({ categoryId: categoryId! });
  const labels = labelResponse.data.labels;
  const nominationLabel = labels?.find(({ name }) => name === IMPACT_REPORT_NOMINATION_LABEL);
  if (nominationLabel) return nominationLabel;

  if (!nominationLabel && doCreate) {
    const labelCreationResponse = await sqContextApi.createLabel({
      name: IMPACT_REPORT_NOMINATION_LABEL,
      categoryId: categoryId!,
    });
    return labelCreationResponse.data;
  }
  return null;
}

/**
 * To "nominate" a worksheet to be turned into an Impact Report we leverage Context labels.
 * Applying the "IMPACT_REPORT_NOMINATION_LABEL" to a worksheet will flag it and make it show up as an option in the
 * header menu.
 */
export async function nominateWorksheetToBecomeImpactReport(worksheetId: string, workbookId: string) {
  const nominationLabel = await getNominationLabel(true);
  if (!nominationLabel) return;
  try {
    await sqContextApi.createContextLabel(
      {
        datumId: workbookId,
        labelId: nominationLabel.id,
      },
      { itemId: worksheetId },
    );
    infoToast({ messageKey: 'IMPACT_REPORT.FLAGGED' });
    return true;
  } catch (e) {
    errorToast({ messageKey: 'IMPACT_REPORT.FLAG_ERROR' });
    return false;
  }
}

/**
 * This function fetches all the pages that have been nominated and are displayed as "Impact Report Nominations" in
 * the application.
 *
 * Remember the "hierarchy" of the context labels:
 * - the Category is the "parent" of the Label
 * - the Label gets then applied as a Context Label
 * - the ID of that context label is what identifies the individual nomination label
 *
 * We also only display nominations to the owners of the workbook (yes -worksheets are impact reports not workbooks,
 * but worksheets inherit their permissions from the workbook - so we need to filter them by workbook owner.
 */
export async function getNominatedWorksheets() {
  // first we have to fetch the "prompt" label (the one that tells us the report was nominated) so we can use its
  // id in the next query:
  const promptLabel = await getNominationLabel();
  const tableId = await getImpactReportTableId();
  if (!promptLabel || !tableId) return;
  // fetch all the items that the "prompt" label was applied to
  const { data: labeledItemsResponse } = await sqContextApi.getLabeledItems({ labelId: promptLabel.id! });
  if (!labeledItemsResponse.items) return;

  // fetch the full worksheet and workbook data for each item - ideally we'd get that all from the query above but
  // this works for now
  // we need to fetch the owner of the workbook to filter out the flagged worksheets that are not owned by the current
  const allFlaggedItems = await Promise.all(
    labeledItemsResponse.items.map(async (item) => {
      const { data: worksheet } = await sqItemsApi.getItemAndAllProperties({ id: item.id! });
      const workbookId = worksheet.workbookId;
      const { data: workbook } = await sqWorkbooksApi.getWorkbook({ id: workbookId! });

      if (workbook?.owner?.id === sqWorkbenchStore.currentUser?.id) {
        return {
          name: `${workbook.name} - ${worksheet.name}`,
          workbookId,
          worksheetId: item.id,
          owner: workbook.owner?.id,
        };
      }
    }),
  );

  // now we can filter out all the flagged worksheets that are not owned by the current user
  const candidates: (FlaggedItem | undefined)[] = allFlaggedItems.filter(
    (item) => item && item.owner === sqWorkbenchStore.currentUser?.id,
  );
  // and finally we check if the worksheet is already an impact report
  const finalists = await Promise.all(
    candidates.map(async (candidate) => {
      if (!candidate) return null;
      const existing = await getImpactReportByWorksheet(tableId, candidate.worksheetId, candidate.workbookId!);
      if (existing) {
        return null;
      }
      const labelId: string = _.first(await getWorksheetLabels(candidate.worksheetId!))!; // this is the id of the applied label
      return { ...candidate, labelId };
    }),
  );
  // we're done :) now we have a list of all nominated worksheets that are owned by the current user not impact
  // reports already
  return _.compact(finalists);
}

/**
 * Replaces "Journal Seeq Links" with builder links so the expected workstep/item/date range is displayed.
 */
export function replaceLinks(input: string, impactReportWorkbookId?: string, impactReportWorksheetId?: string): string {
  const replacements: { [key: string]: string } = {
    workstep: 'workbook/builder?workbookId=$1&worksheetId=$2&workstepId=$3&startFresh=false',
    condition: 'workbook/builder?trendItems=$1',
    signal: 'workbook/builder?trendItems=$1',
    scalar: 'workbook/builder?trendItems=$1',
    range:
      `workbook/builder?workbookId=${impactReportWorkbookId}&worksheetId=${impactReportWorksheetId}` +
      '&startFresh=false&displayStartTime=$1&displayEndTime=$2&investigateStartTime=$1&investigateEndTime=$2',
    capsule:
      'workbook/builder?trendItems=$1&displayStartTime=$2&displayEndTime=$3' +
      '&investigateStartTime=$2&investigateEndTime=$3',
  };

  // this regex matches all possible journal seeq links and replaces them with builder links:
  // it matches the type of link (workstep, condition, signal, scalar, range, capsule) as well as the relevant IDs
  // and uses the replacements object (defined above) to generate the builder link
  return input.replace(
    /href="\/links\?type=(workstep|condition|signal|scalar|range|capsule)&amp;(?:workbook=([a-fA-F0-9-]+)&amp;worksheet=([a-fA-F0-9-]+)&amp;workstep=([a-fA-F0-9-]+)|item=([a-fA-F0-9-]+)|start=([a-fA-F0-9-]+)&amp;end=([a-fA-F0-9-]+)|item=([a-fA-F0-9-]+)&amp;start=([a-fA-F0-9-]+)&amp;end=([a-fA-F0-9-]+))"/gi,
    (_, type, wb, ws, wsId, item1, start1, end1, item2, start2, end2) => {
      switch (type.toLowerCase()) {
        case 'workstep':
          return `href="${replacements[type.toLowerCase()].replace('$1', wb).replace('$2', ws).replace('$3', wsId)}"`;
        case 'range':
          return `href="${replacements[type.toLowerCase()].replace('$1', start1).replace('$2', end1)}"`;
        case 'capsule':
          return `href="${replacements[type.toLowerCase()]
            .replace('$1', item2)
            .replace('$2', String(parseInt(start2, 10)))
            .replace('$3', end2)}"`;
        default: // Handles 'condition', 'signal', and 'scalar'
          return `href="${replacements[type.toLowerCase()]?.replace('$1', item1)}"`;
      }
    },
  );
}

export async function removeNomination(labelId: string, itemId: string) {
  try {
    await sqContextApi.deleteContextLabel({ labelId, itemId });
    return true;
  } catch (e) {
    return false;
  }
}
