import log4javascript from 'log4javascript';
import { log, LOG_ROOT_NAME } from '@/utilities/logger.utilities';

const ajaxAppender = new log4javascript.AjaxAppender('/client-log');
const jsonLayout = new log4javascript.JsonLayout();
const logger = log4javascript.getLogger(LOG_ROOT_NAME);

log4javascript.logLog.setQuietMode(true); // Don't throw popups if it has problems

ajaxAppender.addHeader('Content-Type', 'application/json;charset=utf-8');
// We don't use the batch ability because it doesn't post until batch size is reached, so this removes the array
// brackets which makes it easier to parse in Loki
(jsonLayout as any).batchHeader = '';
(jsonLayout as any).batchFooter = '';
ajaxAppender.setLayout(jsonLayout);
ajaxAppender.setThreshold(log4javascript.Level.WARN);
logger.setLevel(log4javascript.Level.DEBUG);
logger.addAppender(ajaxAppender);

export function logTrace(message: string | Error, category?: string) {
  log('trace', message, category);
}

export function logDebug(message: string | Error, category?: string) {
  log('debug', message, category);
}

export function logInfo(message: string | Error, category?: string) {
  log('info', message, category);
}

export function logError(message: string | Error, category?: string) {
  log('error', message, category);
}

export function logFatal(message: string | Error, category?: string) {
  log('fatal', message, category);
}

export function logWarn(message: string | Error, category?: string) {
  log('warn', message, category);
}
