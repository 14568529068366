import { PersistenceLevel, Store } from '@/core/flux.service';
import _ from 'lodash';

/**
 * Store for the scaling table editor
 * Table definition state is not stored in worksteps, so we separate the logic of what table is being looked at
 * here, which is stored in the workstep, while the table definition state is stored in the table definition store
 * which is updated when we receive messages from the server on the socket for table definitions
 */
export class ScalingTableEditorStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'scalingTableStore';

  initialize() {
    this.state = this.immutable({
      scalingTableId: '',
    });
  }

  get scalingTableId(): string {
    return this.state.get('scalingTableId');
  }

  protected readonly handlers = {
    SET_SCALING_TABLE_ID: (payload: { scalingTableId: string }) => {
      this.state.set('scalingTableId', payload.scalingTableId);
    },
    RESET_SCALING_TABLE_STORE: () => {
      this.initialize();
    },
  };

  /**
   * Dehydrates the item by retrieving the current set parameters in view
   *
   * @returns {Object} An object with the state properties as JSON
   */
  dehydrate() {
    return _.pick(this.state.serialize(), ['scalingTableId']);
  }

  /**
   * Rehydrates item from dehydrated state
   *
   * @param {Object} dehydratedState - State object that should be restored
   */
  rehydrate(dehydratedState: { scalingTableId: string }) {
    this.state.merge(dehydratedState);
  }
}
