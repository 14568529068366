// @ts-strict-ignore
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { MouseEvent } from 'react';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { getCapsuleFormula } from '@/datetime/dateTime.utilities';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { METRIC_COLORS } from '@/toolSelection/investigate.constants';
import { sqMetricsApi } from '@/sdk/api/MetricsApi';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { swapAsset } from '@/search/searchResult.utilities.service';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import {
  extractGuids,
  formatApiError,
  isPresentationWorkbookMode,
  isStringSeries,
  validateGuid,
} from '@/utilities/utilities';
import { COLUMNS_AND_STATS, ITEM_TYPES, PropertyColumn, StatisticColumn } from '@/trendData/trendData.constants';
import { isCanceled } from '@/utilities/http.utilities';
import { AUTO_CLOSE_INTERVAL_LONG, errorToast, infoToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import i18next from 'i18next';
import {
  ANCESTOR_COLUMN_NAME,
  MAX_CONDITION_TABLE_CAPSULES,
  TABLE_BUILDER,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode,
} from '@/tableBuilder/tableBuilder.constants';
import {
  sqDurationStore,
  sqScorecardStore,
  sqTableBuilderStore,
  sqTrendScalingColumnStore,
  sqTrendStore,
  sqWorkbenchStore,
  sqWorkbookStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { priorityColors } from '@/services/systemConfiguration.utilities';
import {
  addTrendItem,
  alignMeasuredItemWithMetric,
  catchItemDataFailure,
  removeItems,
  setItemSelected,
  setItemStatusNotRequired,
  setTrendSelectedRegion,
  toggleHideUnselectedItems,
} from '@/trendData/trend.actions';
import {
  canFetchData,
  computeCapsuleTable,
  computeScalar,
  computeTable,
  getDefaultName,
  getDependencies,
} from '@/utilities/formula.utilities';
import { getStatisticFragment } from '@/utilities/calculationRunner.utilities';
import { setOriginalView, setView } from '@/worksheet/worksheet.actions';
import { ScorecardMetric } from '@/tableBuilder/scorecard.types';
import { Item } from '@/utilities/items.types';
import { notifyWarning } from '@/utilities/screenshot.utilities';
import { ColumnDefinitionInputV1, ItemFinderInputV1, sqTableDefinitionsApi } from '@/sdk';

import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionOutputV1';
import { SearchTypeEnum } from '@/sdk/model/ScalingTableUUIDColumnSearchV1';
import { BatchActionEnum, TableTypeEnum } from '@/sdk/model/TableDefinitionInputV1';
import { doTrack } from '@/track/track.service';
import { fetchMaterializedTable } from '@/tableDefinitionEditor/materializedTable.utilities';
import {
  MaterializedTableFormulaInput,
  MaterializedTablePropertyColumnInput,
} from '@/tableDefinitionEditor/materializedTable.types';

export const DATA_CANCELLATION_GROUP = 'tableBuilder';
export const HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS = [
  'is not compatible with',
  'non-linear, cannot convert',
  'Rows with different units are not allowed',
];

const NAME_ITEM_PROPERTY = 'name';

/**
 * Sets the mode of the table builder
 *
 * @param mode - The mode
 */
export function setMode(mode: TableBuilderMode) {
  flux.dispatch('TABLE_BUILDER_SET_MODE', { mode });
  setItemStatusNotRequired();
  exposedForTesting.fetchTable();
}

/**
 * Adds a column to the table that the user can use to input free-form text.
 */
export function addTextColumn() {
  flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
    type: TableBuilderColumnType.Text,
  });
}

/**
 * Adds an ancestor column to the simple table
 *
 * @param assetPathId - The asset path id to identify which asset path is being added to the table
 * @param assetPathLevel - The level of the asset path
 */
export function addAssetPathColumn(assetPathId: string, assetPathLevel: number) {
  flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
    assetPathId,
    assetPathLevel,
    type: TableBuilderColumnType.AssetPath,
  });
  exposedForTesting.fetchTable();
}

/**
 * Adds an ancestor column to the table definition if an ancestor column at a specified level does not already exist
 * in the table definition. The ancestor column is added to the table definition using a specific name format (e.g.
 * 'Ancestor 1', 'Ancestor 2', etc.). The column is added to the table definition with the ancestor column rule.
 *
 * @param assetPathLevel - The level of the asset path
 */
export async function addAncestorColumnToTableDefinition(assetPathLevel: number) {
  if (!sqTableBuilderStore.tableDefinitionId) {
    return;
  }

  try {
    const { data } = await sqTableDefinitionsApi.getTableDefinition({
      id: sqTableBuilderStore.tableDefinitionId,
    });

    const columnDefinitionNames = data.columnDefinitions.map((columnDefinition) => columnDefinition.columnName);

    if (!columnDefinitionNames.includes(`${ANCESTOR_COLUMN_NAME} ${assetPathLevel}`)) {
      const newColumnDefinition: ColumnDefinitionInputV1 = {
        columnName: `${ANCESTOR_COLUMN_NAME} ${assetPathLevel}`,
        columnType: ColumnTypeEnum.UUID,
        columnRules: [{ ancestor: { columnIndex: 1, level: assetPathLevel } }],
      };

      await sqTableDefinitionsApi.addColumnsToTableDefinition(
        { columnDefinitions: [newColumnDefinition] },
        { id: sqTableBuilderStore.tableDefinitionId },
      );
    }
  } catch (error) {
    errorToast({ httpResponseOrError: error });
  }
}

/**
 * Adds an item property column to the table
 *
 * @param column - The property column to add
 * @param [isCapsuleProperty] - True if this is a capsule property, false if it is a property on an item.
 */
export function addPropertyColumn(
  column: { propertyName: string; style: string | undefined },
  isCapsuleProperty = false,
) {
  flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
    type: isCapsuleProperty ? TableBuilderColumnType.CapsuleProperty : TableBuilderColumnType.Property,
    style: column.style,
    propertyName: column.propertyName,
  });
  exposedForTesting.fetchTable();
}

/**
 * Removes the specified column from the table
 *
 * @param key - The key that identifies the column
 */
export function removeColumn(key: string) {
  if (_.has(_.find(sqTableBuilderStore.columns, { key }), 'filter')) {
    setColumnFilter(key, undefined);
  }
  flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
}

/**
 * Adds or removes a particular column into the table builder store.
 *
 * @param column - The column being toggled. One of COLUMNS_AND_STATS
 * @param [signalId] - The series if it is a statistic column for condition table
 */
export function toggleColumn(column: PropertyColumn | StatisticColumn, signalId: string = null) {
  if (sqTableBuilderStore.isColumnEnabled(column, signalId)) {
    exposedForTesting.removeColumn(sqTableBuilderStore.getColumnKey(column, signalId));
  } else {
    const uom = COLUMNS_AND_STATS.valueUnitOfMeasure;
    const disableUnitHomogenization =
      column.key === uom.key && !_.isUndefined(sqTableBuilderStore.assetId) && sqTableBuilderStore.isHomogenizeUnits;
    if (disableUnitHomogenization) {
      infoToast({
        messageKey: 'TABLE_BUILDER.UNIT_HOMOGENIZATION_DISABLED',
      });
      exposedForTesting.setHomogenizeUnits(false, false);
    }

    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column, signalId });

    exposedForTesting.fetchTable();
  }
}

/**
 * Moves the specified column to a new position
 *
 * @param key - The key that identifies the column.
 * @param newKey - The key that identifies the column that will be the new position
 */
export function moveColumn(key: string, newKey: string) {
  flux.dispatch('TABLE_BUILDER_MOVE_COLUMN', { key, newKey });
}

export function addMetricColumn(metricItem: { id: string }) {
  flux.dispatch('TABLE_BUILDER_ADD_METRIC_TO_CONDITION_TABLE', {
    metricId: metricItem.id,
  });
}

export function isTableColumnEnabled(column: PropertyColumn | StatisticColumn, signalId: string = null) {
  return sqTableBuilderStore.isColumnEnabled(column, signalId);
}

/**
 * Sets the background color for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param color - Background color for the column
 */
export function setColumnBackground(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_BACKGROUND', { key, color });
}

/**
 * Sets the text alignment for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param align - CSS text-align value
 */
export function setColumnTextAlign(key: string, align: string) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_ALIGN', { key, align });
}

/**
 * Sets the text color for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param color - Text color
 */
export function setColumnTextColor(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_COLOR', { key, color });
}

/**
 * Sets the text style for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param style - zero or more text style attributes
 */
export function setColumnTextStyle(key: string, style: string[]) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_STYLE', { key, style });
}

/**
 * Sets the background color for a table header.
 *
 * @param key - The key that identifies the column.
 * @param color - Background color for the header
 */
export function setHeaderBackground(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_BACKGROUND', { key, color });
}

/**
 * Sets the text alignment for a table header.
 *
 * @param key - The key that identifies the column.
 * @param align - CSS text-align value
 */
export function setHeaderTextAlign(key: string, align: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_ALIGN', { key, align });
}

/**
 * Sets the text color for a table header.
 *
 * @param key - The key that identifies the column.
 * @param color - Text color
 */
export function setHeaderTextColor(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_COLOR', { key, color });
}

/**
 * Sets the text style for a table header.
 *
 * @param key - The key that identifies the column.
 * @param style - zero or more text style attributes
 */
export function setHeaderTextStyle(key: string, style: string[]) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_STYLE', { key, style });
}

/**
 * Applies the formatting of the specified column to all columns (headers excluded)
 * @param key - The key that identifies the column.
 */
export function setStyleToAllColumns(key: string) {
  flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_COLUMNS', { key });
}

/**
 * Applies the formatting of the specified column to all headers
 * @param key - The key that identifies the column.
 */
export function setStyleToAllHeaders(key: string) {
  flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS', { key });
}

/**
 * Applies the formatting of the specified column&header to all columns and headers
 * @param key - The key that identifies the column.
 */
export function setStyleToAllHeadersAndColumns(key: string) {
  flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS_AND_COLUMNS', {
    key,
  });
}

/**
 * Copies the formatting of the specified column&header.
 * @param key - The key that identifies the column.
 */
export function copyStyle(key: string) {
  flux.dispatch('TABLE_BUILDER_COPY_STYLE', { key });
}

/**
 * Applies the copied formatting to the specified column header
 * @param key - The key that identifies the column.
 */
export function pasteStyleOnHeader(key: string) {
  flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER', { key });
}

/**
 * Applies the copied formatting to the specified column (header excluded)
 * @param key - The key that identifies the column.
 */
export function pasteStyleOnColumn(key: string) {
  flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_COLUMN', { key });
}

/**
 * Applies the copied formatting to the specified column&header
 * @param key - The key that identifies the column.
 */
export function pasteStyleOnHeaderAndColumn(key: string) {
  flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER_AND_COLUMN', { key });
}

/**
 * Filter a column in the Simple Table
 *
 * @param key - The key that identifies the column.
 * @param filter - filter to apply to the column
 */
export function setColumnFilter(key: string, filter: TableColumnFilter) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_FILTER', { key, filter });
}

export function sortByColumn(key: string, direction: string) {
  flux.dispatch('TABLE_BUILDER_SORT_BY_COLUMN', { key, direction });
}

/**
 * Sets the text for a scorecard column cell or header.
 *
 * @param key - The key that identifies the column.
 * @param text - Text for the cell
 * @param [cellKey] - The identifier for the cell. If not specified the column header text will be set.
 */
export function setCellText(key: string, text: string, cellKey?: string) {
  flux.dispatch('TABLE_BUILDER_SET_CELL_TEXT', { key, text, cellKey });
}

/**
 * Sets the text for a table builder column header.
 *
 * @param columnKey - The key that identifies the column.
 * @param text - Text for the header
 */
export function setHeaderText(columnKey: string, text: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT', { columnKey, text });
}

/**
 * Sets the header override flag for a column. Other column overrides will be disabled.
 *
 * @param columnKey - The key that identifies the column.
 */
export function setHeaderOverridden(columnKey: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_OVERRIDE', { columnKey });
}

/**
 * Sets the header type for either scorecard columns that display the metric values or the name column for simple
 * tables.
 *
 * @param type - The type of header to display
 */
export function setHeadersType(type: TableBuilderHeaderType) {
  flux.dispatch('TABLE_BUILDER_SET_HEADERS_TYPE', { type });
  if (type === TableBuilderHeaderType.CapsuleProperty) {
    exposedForTesting.fetchTable();
  }
}

/**
 * Sets the date format used for headers of metric value columns/name column when the type is one of the date types.
 *
 * @param format - A string that can be passed to moment's format()
 */
export function setHeadersFormat(format: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADERS_FORMAT', { format });
}

/**
 * Sets the name of the capsule property used for headers of metric value columns when the type is CapsuleProperty.
 *
 * @param property - The capsule property name
 */
export function setHeadersProperty(property: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADERS_PROPERTY', { property });
  exposedForTesting.fetchTable();
}

export function setIsTransposed(isTransposed: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_IS_TRANSPOSED', { isTransposed });
}

/**
 * Sets or clears the asset id so the table can be run across all the child assets of the asset.
 *
 * @param assetId - The root asset to run the formula across or undefined to clear.
 */
export function setAssetId(assetId: string | undefined) {
  flux.dispatch('TABLE_BUILDER_SET_ASSET_ID', { assetId });
  if (assetId) {
    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column: { key: 'asset' } });
  } else {
    flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key: 'asset' });
  }

  exposedForTesting.fetchTable();
}

/**
 * Sets the homogenizeUnits attribute and removes the UOM column when the units are homogenized. In this case, we do
 * not want to show the UOM column. The unit of some values might be different than the unit of the item
 * @param homogenizeUnits - The homogenize units value
 * @param fetchTable - When true, it fetches the table
 * @return void if only homogenizeUnits is set. When fetch table is needed it returns a promise resolves when the
 * table has been been fetched
 */
export function setHomogenizeUnits(homogenizeUnits: boolean, fetchTable = false): void | Promise<void | any[]> {
  flux.dispatch('TABLE_BUILDER_SET_HOMOGENIZE_UNITS', { homogenizeUnits });
  if (
    homogenizeUnits &&
    sqTableBuilderStore.isSimpleMode() &&
    sqTableBuilderStore.isColumnEnabled(COLUMNS_AND_STATS.valueUnitOfMeasure)
  ) {
    const key = COLUMNS_AND_STATS.valueUnitOfMeasure.key;
    flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
    infoToast({
      messageKey: 'TABLE_BUILDER.UNIT_COLUMN_REMOVED',
    });
  }

  if (fetchTable) {
    return exposedForTesting.fetchTable();
  }
}

/**
 * Changes to the specified the asset id only if a current asset is already set and the new one is different.
 *
 * @param assetId - The asset to change to
 */
export function changeAssetId(assetId: string) {
  if (sqTableBuilderStore.assetId && sqTableBuilderStore.assetId !== assetId) {
    exposedForTesting.setAssetId(assetId);
  }
}

export function setIsMigrating(isMigrating: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_IS_MIGRATING', { isMigrating });
}

export function setIsTableStriped(isTableStriped: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_IS_TABLE_STRIPED', { isTableStriped });
}

export function setShowChartView(showChart: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW', { enabled: showChart });
}

export function setShowConditionChartView(showChart: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_CONDITION_CHART_VIEW', { conditionEnabled: showChart });
}

/**
 * True/false toggle gives user a choice between "Signal colors" and "Seeq colors" for the Highcharts chart
 * in the Tables & Charts section of Workbench Analysis. When true, the colors selected in the Details
 * pane are used, unless something overrides the choice (such as being in Transposed mode).
 * @param useSignalColorsInChart - When true, uses the signal colors defined in the Details pane below the chart
 * @return void
 */
export function setUseSignalColorsInChart(useSignalColorsInChart: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_USE_SIGNAL_COLORS_IN_CHART', { useSignalColorsInChart });
}

export function setAssetPaths(assetPaths: any[]) {
  flux.dispatch('TABLE_BUILDER_SET_ASSET_PATHS', { assetPaths });
}

export function setChartViewSettings(settings: any) {
  flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW_SETTINGS', {
    settings: { ...settings },
  });
}

export function setChartViewConditionSettings(settings: any) {
  flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW_CONDITION_SETTINGS', {
    conditionSettings: settings,
  });
}

/**
 * Remove the v1 metric.
 *
 * @param {string} metricId - The id of the metric
 */
export function removeOldMetric(metricId) {
  flux.dispatch('SCORECARD_REMOVE_METRIC', { metricId });
}

/**
 * Fetches and dispatches the table data.
 *
 * @return {Promise} A promise that resolves when the table has been been fetched
 */
export function fetchTable(): Promise<void | any[]> {
  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE) {
    return Promise.resolve();
  }
  cancelGroup(DATA_CANCELLATION_GROUP, false);
  if (sqScorecardStore.metrics.length) {
    return exposedForTesting.fetchOldMetrics();
  } else if (sqTableBuilderStore.isSimpleMode()) {
    if (sqTableBuilderStore.isFormulaAcrossTable) {
      return exposedForTesting.fetchFormulaAcrossSimpleTableData();
    } else {
      return exposedForTesting.fetchSimpleTableData();
    }
  } else {
    return exposedForTesting.fetchConditionTableData();
  }
}

/**
 * Fetches and dispatches the simple table data.
 * Because the calc engine requires that the type information be the same for all rows, each type of item in the
 * details pane is fetched separately and then combined into a single table for the simple table.
 * @return A promise that resolves when the table has been been fetched
 */
export async function fetchSimpleTableData(): Promise<void | any[]> {
  const items = sqTableBuilderStore.getTableItemsProcess();

  const isCondition = (item) => item.itemType === ITEM_TYPES.CONDITION;
  const isSignal = (item) => item.itemType === ITEM_TYPES.SERIES;
  const isScalar = (item) => item.itemType === ITEM_TYPES.SCALAR;
  const stringItems = _.filter(items, (item) => isStringSeries(item) && isSignal(item));
  const numericItems = _.reject(
    items,
    (item) => isCondition(item) || isScalar(item) || isStringSeries(item) || !isSignal(item),
  );
  const conditionItems = _.filter(items, (item) => isCondition(item));
  const scalarItems = _.filter(items, (item) => isScalar(item));

  const allMetricItems = getAllItems({ workingSelection: false, itemTypes: [ITEM_TYPES.METRIC] });
  const metricMap = sqTableBuilderStore.findNonConflictingSimpleMetrics(items.map((it) => it.id));

  const selectedMetricItems = _.reject(items, (item) => isCondition(item) || isSignal(item) || isScalar(item));
  // All the metrics where a given selected item is tied to just that metric and no other metrics, and that metric
  // is not included in the selected items
  const extraMetricsToFetch = _.chain(items)
    .map((item) => metricMap[item.id])
    .compact()
    .filter((mapping) => _.size(mapping) === 1)
    .map((mapping) => _.values(mapping)[0])
    .filter((metricId) => !selectedMetricItems.find((metric) => metric.id === metricId))
    .map((metricId) => allMetricItems.find((metric) => metric.id === metricId))
    .value();

  const metricItems = selectedMetricItems.concat(extraMetricsToFetch);

  const numericSignalParameters = sqTableBuilderStore.getSimpleTableFetchParams(numericItems);
  const stringSignalParameters = sqTableBuilderStore.getSimpleTableFetchParams(stringItems);
  const conditionParameters = sqTableBuilderStore.getSimpleTableFetchParams(conditionItems);
  const scalarParameters = sqTableBuilderStore.getSimpleTableFetchParams(scalarItems);
  const metricParameters = sqTableBuilderStore.getSimpleTableFetchParams(metricItems);

  if (
    _.isEmpty(numericSignalParameters.formula) &&
    _.isEmpty(stringSignalParameters.formula) &&
    _.isEmpty(conditionParameters.formula) &&
    _.isEmpty(scalarParameters.formula) &&
    _.isEmpty(metricParameters.formula)
  ) {
    return Promise.resolve();
  }

  const itemIds = _.chain([numericItems, stringItems, conditionItems, scalarItems, metricItems])
    .flatMap()
    .map('id')
    .filter(validateGuid)
    .value();

  _.forEach(itemIds, (id) => {
    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
  });
  try {
    const computeTableIfNeeded = async (items: Item[], parameters: { formula: string; parameters; root: string }) => {
      if (items.length > 0) {
        return await computeTable({
          formula: parameters.formula,
          parameters: parameters.parameters,
          root: parameters.root,
          cancellationGroup: DATA_CANCELLATION_GROUP,
          usePost: true,
          itemsToScaleAcross: items.map((item) => item.id),
        });
      }
      return Promise.resolve();
    };

    const [numericTable, stringTable, conditionTable, scalarTable, metricTable] = await Promise.all([
      computeTableIfNeeded(numericItems, numericSignalParameters),
      computeTableIfNeeded(stringItems, stringSignalParameters),
      computeTableIfNeeded(conditionItems, conditionParameters),
      computeTableIfNeeded(scalarItems, scalarParameters),
      computeTableIfNeeded(metricItems, metricParameters),
    ]);

    flux.dispatch(
      'TABLE_BUILDER_PUSH_SIMPLE_DATA',
      {
        tableAndColumnPositions: [
          [numericTable, numericSignalParameters.columnPositions],
          [stringTable, stringSignalParameters.columnPositions],
          [conditionTable, conditionParameters.columnPositions],
          [scalarTable, scalarParameters.columnPositions],
          [metricTable, metricParameters.columnPositions],
        ],
        idsToFilterOut: extraMetricsToFetch.map((item) => item.id),
        items: items.concat(extraMetricsToFetch),
      },
      PUSH_IGNORE,
    );

    _.forEach(
      items.concat(extraMetricsToFetch).map((item) => item.id),
      (id) => {
        const tableIndex = _.findIndex(
          [numericItems, stringItems, conditionItems, scalarItems, metricItems, extraMetricsToFetch],
          (itemsArray) => _.some(itemsArray, (item) => item.id === id),
        );
        const tables = [numericTable, stringTable, conditionTable, scalarTable, metricTable];

        const correctWarningLogs = getCorrectWarningLogs(id, tables[tableIndex].warningLogs);

        flux.dispatch(
          'TREND_SET_DATA_STATUS_PRESENT',
          {
            id,
            warningCount: tables[tableIndex].warningCount,
            warningLogs: correctWarningLogs,
            timingInformation: tables[tableIndex].timingInformation,
            meterInformation: tables[tableIndex].meterInformation,
            isSharedRequest: true,
          },
          PUSH_IGNORE,
        );
        if (tables[tableIndex].warningCount > 0) {
          notifyWarning(tables[tableIndex].warningLogs[0]?.formulaLogEntries?.SWAP_ERROR?.logDetails[0]?.message);
        }
      },
    );
    // not waiting for a promise - fetches options for the dropdown in the filter
    exposedForTesting.fetchSimpleTableDistinctStringValues();
  } catch (e) {
    return exposedForTesting.catchTableBuilderDataFailure(
      e,
      TableBuilderMode.Simple,
      sqTableBuilderStore.getTableItems(),
    );
  }
}

/** Parses warnings from batch computations (FormulaAcrossItems), ensuring only relevant items retain warningLogs.
 *  Cleans up messages to remove Ids.
 */
function getCorrectWarningLogs(id: string, warningLogs: any[]) {
  if (!warningLogs || warningLogs.length === 0) {
    return [];
  }

  const warningMessagesWithIds = warningLogs.flatMap(
    (warningLog) =>
      warningLog.formulaLogEntries?.SWAP_ERROR?.logDetails?.map((detail: { message: any }) => detail.message) ?? [],
  );

  const allItemIdsWithWarnings = warningMessagesWithIds.flatMap((itemIdString) => extractGuids(itemIdString));

  if (!allItemIdsWithWarnings.includes(id?.toString().toLowerCase())) {
    return allItemIdsWithWarnings.length > 0 ? [] : warningLogs;
  }

  return warningLogs.map((log) => ({
    ...log,
    formulaLogEntries: {
      ...log.formulaLogEntries,
      SWAP_ERROR: {
        ...log.formulaLogEntries?.SWAP_ERROR,
        logDetails: log.formulaLogEntries?.SWAP_ERROR?.logDetails?.map((detail: { message: string }) => ({
          ...detail,
          message: extractGuids(detail.message).reduce((memo, guid) => memo.replace(`[${guid}]`, ''), detail.message),
        })),
      },
    },
  }));
}

/**
 * Fetch the distinct string values for each string-valued column in the Simple Table.
 * Noops in presentation mode since the filters can't be changed.
 */
export function fetchSimpleTableDistinctStringValues(): Promise<void> {
  if (isPresentationWorkbookMode()) {
    return Promise.resolve();
  }

  const { fetchParamsList, columnKeysNamesList } = sqTableBuilderStore.getSimpleTableStringColumnsFetchParams();
  return Promise.all(
    _.map(fetchParamsList, (tableFetchParam) =>
      computeTable({
        formula: tableFetchParam.formula,
        parameters: tableFetchParam.parameters,
        reduceFormula: tableFetchParam.reduceFormula,
        root: tableFetchParam.root,
        cancellationGroup: DATA_CANCELLATION_GROUP,
        usePost: true, // Formula can be very long
      }),
    ),
  ).then((stringValueTables) => {
    flux.dispatch(
      'TABLE_BUILDER_SET_SIMPLE_DISTINCT_STRING_VALUES',
      {
        stringValueTables,
        columnKeysNamesList,
      },
      PUSH_IGNORE,
    );
  });
}

/**
 * Fetches and dispatches the condition table data.
 *
 * @return A promise that resolves when the table has been fetched
 */
export function fetchConditionTableData(): Promise<void | any[]> {
  const {
    ids: itemIds,
    assetId,
    propertyColumns,
    statColumns,
    customPropertyName,
    buildAdditionalFormula,
    itemColumnsMap,
    buildConditionFormula,
    buildStatFormula,
  } = sqTableBuilderStore.getConditionTableFetchParams();

  if (_.isEmpty(itemIds)) {
    return Promise.resolve();
  }

  _.forEach(itemIds, (id) => {
    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
  });

  return computeCapsuleTable({
    columns: { propertyColumns, statColumns },
    range: { start: sqDurationStore.displayRange.start.valueOf(), end: sqDurationStore.displayRange.end.valueOf() },
    itemIds,
    buildConditionFormula,
    root: assetId,
    buildAdditionalFormula,
    buildStatFormula,
    offset: 0,
    limit: MAX_CONDITION_TABLE_CAPSULES,
    cancellationGroup: DATA_CANCELLATION_GROUP,
  })
    .then(({ data: { headers, table, timingInformation, meterInformation, warningCount, warningLogs } }) => {
      flux.dispatch(
        'TABLE_BUILDER_PUSH_CONDITION_DATA',
        { headers, table, itemColumnsMap, customPropertyName },
        PUSH_IGNORE,
      );
      _.forEach(itemIds, (id) => {
        flux.dispatch(
          'TREND_SET_DATA_STATUS_PRESENT',
          {
            id,
            warningCount,
            warningLogs,
            timingInformation,
            meterInformation,
            isSharedRequest: true,
          },
          PUSH_IGNORE,
        );
      });
    })
    .catch((e) =>
      exposedForTesting.catchTableBuilderDataFailure(
        e,
        TableBuilderMode.Condition,
        sqTableBuilderStore.getTableItems(),
      ),
    );
}

/**
 * Performs all necessary steps to execute all v1 metrics using the current display range.
 *
 * @return Promise that resolves when the cell is computed
 */
export function fetchOldMetrics(): Promise<void> {
  return (
    _.chain(sqScorecardStore.metrics)
      .map((metric: ScorecardMetric) => {
        const statFragment = getStatisticFragment(metric.stat);
        const formula = `$series.aggregate(${statFragment}, ${getCapsuleFormula(sqDurationStore.displayRange)})`;

        return computeScalar({ formula, parameters: { series: metric.itemId } }).then((result) => {
          const payload = _.assign(
            {
              metricId: metric.metricId,
              valueResult: `${formatNumber(result.value)} ${result.uom}`,
            },
            exposedForTesting.computeColorForOldMetric(metric, result.value),
          );

          flux.dispatch('SCORECARD_VALUE_RESULT', payload);
        });
      })
      .thru((promises) => Promise.all(promises))
      .value()
      // Noop at the end so we have a void return type
      .then(() => {})
  );
}

/**
 * Compute the color for a given value. This finds the maximum threshold value that is less or equal to the value,
 * and returns that. It also computes the contrasting color for the foreground.
 *
 * @param {Object} metric - The metric object
 * @param {Object} metric.thresholds - Array of threshold objects
 * @param {Number} value - The value to choose colors for
 * @returns {{backgroundColor: string, foregroundColor: string}} - Map of background and foreground colors
 */
export function computeColorForOldMetric(metric, value) {
  const color = (
    _.chain(metric.thresholds)
      .filter(function (t: any) {
        return !_.isUndefined(t.isMinimum) || t.threshold <= value;
      })
      .head() as any
  )
    .get('color', '#ddd')
    .value();
  return {
    backgroundColor: color,
    foregroundColor: tinycolor(color).isDark() ? '#fff' : '#000',
  };
}

/**
 * Displays a metric on the trend with a specific time region of the chart highlighted. It also takes care of
 * swapping to the new asset if the table is going across assets and the row that the user is clicking on is from a
 * different asset than the one currently being shown.
 *
 * @param metricId - The metric identifier
 * @param itemId - The id of the actual item in the row. This can be different from the metricId when swapping
 * @param start - The start time of the window to highlight
 * @param end - The end time of the window to highlight
 * @param event - The click event
 */
export function displayMetricOnTrend(
  metricId: string,
  itemId: string,
  start: number,
  end: number,
  event?: MouseEvent,
): Promise<any> {
  if ((event?.view as any)?.getSelection().toString().length > 0) {
    return Promise.resolve(); // noop if the user is selecting the value
  }

  let metric = _.find(
    getAllItems({
      itemTypes: [ITEM_TYPES.METRIC],
    }),
    { id: metricId },
  );
  const isItemPresent = _.some(getAllItems({}), { id: itemId });
  let promise;
  if (!sqTableBuilderStore.assetId || isItemPresent) {
    promise = Promise.resolve();
  } else {
    // User clicked on a metric whose item is not in the details pane, so swap to its parent
    promise = getDependencies({ id: itemId }).then(({ assets }) => {
      if (assets.length && assets[0].pathComponentIds.length) {
        swapAsset({ id: _.last(assets[0].pathComponentIds) }).then(() => {
          // Actual item clicked should now be in the details pane
          metric = _.find(getAllItems({ itemTypes: [ITEM_TYPES.METRIC] }), { id: itemId });
        });
      }
    });
  }

  return promise.then(() => {
    setOriginalView(WORKSHEET_VIEW.TABLE);
    setView(WORKSHEET_VIEW.TREND);

    const isSimpleMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Simple;
    const isBatchMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Condition;
    const boundingCondition = _.get(metric, 'definition.boundingCondition', {});
    if (isBatchMetric && boundingCondition.id) {
      addTrendItem(boundingCondition).then((bc) => setItemSelected(bc, true));
    }

    _.forEach(getAllItems({}), (item) => setItemSelected(item, item.id === metric.id));

    if (isSimpleMetric) {
      addTrendItem(metric.definition.measuredItem).then(() => alignMeasuredItemWithMetric(metric));
    }

    if (sqDurationStore.displayRange.start.valueOf() !== start || sqDurationStore.displayRange.end.valueOf() !== end) {
      setTrendSelectedRegion(start, end);
    }

    if (!sqTrendStore.hideUnselectedItems) {
      toggleHideUnselectedItems();
    }
  });
}

/**
 * Migrates old scorecard to be backend threshold metric items.
 */
export function migrate() {
  exposedForTesting.setIsMigrating(true);
  exposedForTesting.setMode(TableBuilderMode.Simple);
  exposedForTesting.setHeadersType(TableBuilderHeaderType.None);
  exposedForTesting.removeColumn(COLUMNS_AND_STATS['statistics.average'].key);
  exposedForTesting.toggleColumn(COLUMNS_AND_STATS.metricValue);
  _.chain(sqScorecardStore.metrics)
    .map((metric: any) =>
      exposedForTesting
        .getName(metric)
        .then((name) =>
          sqMetricsApi.createThresholdMetric({
            name,
            measuredItem: metric.itemId,
            aggregationFunction: getStatisticFragment(metric.stat),
            thresholds: exposedForTesting.getThresholds(metric),
          }),
        )
        .then(({ data: item }) => {
          exposedForTesting.removeOldMetric(metric.metricId);
          return item;
        })
        .catch((error) => {
          errorToast({ httpResponseOrError: error });
        }),
    )
    .thru((promises) => Promise.all(promises))
    .value()
    // Important that they are added in order to preserve the sort
    .then((items) =>
      Promise.all(
        _.map(items, (item) => {
          const promise = addTrendItem(item);
          setItemSelected(item, true);
          return promise;
        }),
      ),
    )
    .finally(() => {
      exposedForTesting.setIsMigrating(false);
      successToast(
        {
          messageKey: 'TABLE_BUILDER.MIGRATION_SUCCESS',
        },
        { autoClose: AUTO_CLOSE_INTERVAL_LONG },
      );
    });
}

/**
 * Returns a name for the metric. Specifically handles the case of metric that had an empty name. It is not
 * guaranteed to be unique. Instead, since the backend does not enforce uniqueness, it assumes the user will figure
 * out the best way to disambiguate duplicate names when they edit it.
 *
 * @param {Object} metric - The metric
 * @return {Promise<String>} - Promise that resolves with a name for the metric
 */
export function getName(metric) {
  return Promise.resolve(_.trim(metric.name))
    .then((name) => {
      if (_.isEmpty(name)) {
        return sqItemsApi.getItemAndAllProperties({ id: metric.itemId }).then(({ data: item }) => {
          const statTitle = i18next.t(
            _.get(_.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, ['key', _.get(metric.stat, 'key')]), 'title'),
          );

          return `${statTitle} ${item.name}`;
        });
      }

      return name;
    })
    .then((name) => getDefaultName(name, sqWorkbenchStore.stateParams.workbookId))
    .then((defaultName) => {
      // getDefaultName name will add a suffix, but if that suffix is 1 the name is unique so we don't need the
      // number
      if (_.endsWith(defaultName, ' 1')) {
        return defaultName.substr(0, defaultName.length - 2);
      }

      return defaultName;
    });
}

/**
 * Gets the thresholds for a metric, mapped to the new priority levels. Makes no assumptions that the colors are
 * the same, but instead makes the assumption that the user ordered their metrics with the same priority order as
 * the order presented by METRIC_COLORS. It has the following known limitations:
 *  - It can assign the same level to two different thresholds if there is no corresponding new priority. This
 *  could happen, for example, if user used both the green and blue as thresholds, since blue was removed in the
 *  new scorecard.
 *  - If the thresholds have the colors in a random order, that order will not be preserved since the order cannot
 *  be changed for the new priorities.
 *  - If the user defined more thresholds than the number of new priorities then some of the old thresholds will
 *  be lost.
 *
 * @param {Object} metric - The metric
 * @return {String[]} Array of thresholds in the format of priorityLevel=value
 */
export function getThresholds(metric): string[] {
  const highPriorities = _.filter(priorityColors(), (priority) => priority.level > 0);
  const lowercaseMetricColors = _.map(METRIC_COLORS, _.toLower);
  const priorityConversionMap = _.chain(lowercaseMetricColors)
    .initial() // discard the last color (white) since neutral is not included in priorities
    .reverse() // reverse it so that the indices correspond with the priority levels
    .transform((result, color, i) => {
      // Use the index to find the corresponding priority. Green's index is zero and with this algorithm that
      // means it ends up as the neutral priority, but that is ok since R21 moved green to a neutral color. The
      // rest of the colors will then map to their new corresponding priority level.
      result[color] = _.get(_.find(highPriorities, { level: i }), 'level', _.last(highPriorities).level);
    }, {} as Record<string, number>)
    .value();

  // Needed because some old scorecards somehow have some of their colors as strings instead of hex codes
  const thresholds = _.map(metric.thresholds, (threshold: any) => {
    // yellow yields a different hex code
    const colorObj = threshold.color === 'yellow' ? tinycolor(METRIC_COLORS[1]) : tinycolor(threshold.color);
    const color = colorObj.isValid() ? colorObj.toHexString() : threshold.color;
    return { ...threshold, color };
  });

  return _.chain(thresholds)
    .transform((result, threshold: any, i) => {
      // Last one will not have a threshold value
      if (i === thresholds.length - 1) {
        return;
      }

      const currentColorIndex = _.indexOf(lowercaseMetricColors, threshold.color);
      const nextColorIndex = _.indexOf(lowercaseMetricColors, thresholds[i + 1].color);
      // Can tell if the priorities are high or low since the old METRIC_COLORS went from high to low
      const isHigh = currentColorIndex <= nextColorIndex;
      const color = lowercaseMetricColors[isHigh ? currentColorIndex : nextColorIndex];
      // White thresholds that were not used as neutral will not be in the map
      if (_.has(priorityConversionMap, color)) {
        const level = priorityConversionMap[color] * (isHigh ? 1 : -1);
        result.push([level, threshold.threshold]);
      }
    }, [])
    .uniqBy(_.head)
    .map(([level, threshold]) => `${level}=${threshold}`)
    .value();
}

/**
 * Error handler for when fetching the table data fails. It checks to see if changing the homogenize units setting
 * would help. Otherwise, it tries to determine which items that were used in the table builder formula are
 * causing the problem by fetching each one individually. This is expensive, but there's no other good way to
 * figure it out.
 *
 * @param error - The error that arose from fetching the data
 * @param mode - The current table builder mode
 * @param items - The items that were used to generate the table builder formula
 */
export function catchTableBuilderDataFailure(error: any, mode: TableBuilderMode, items: any[]) {
  let apiMessage: string;
  let fetchFailedMessage = formatApiError(error).replace(/\((GET|POST) .*?\)/, '');
  const isHomogenizeError = HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS.some((err) => fetchFailedMessage.includes(err));
  const isAssetError = fetchFailedMessage.includes('asset');
  const shouldFallbackToUnitless = !!sqTableBuilderStore.assetId && isHomogenizeError;

  if (shouldFallbackToUnitless) {
    warnToast({
      messageKey: 'TABLE_BUILDER.INCOMPATIBLE_UNITS_ACROSS_ASSETS',
      messageParams: { message: fetchFailedMessage },
    });
    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
      column: COLUMNS_AND_STATS.valueUnitOfMeasure,
    });
    return exposedForTesting.setHomogenizeUnits(false, true);
  } else {
    let checkFailureForEachItem = false;
    if (isCanceled(error)) {
      fetchFailedMessage = undefined; // Request will be retried so don't flash an error message
    } else {
      if (_.isEmpty(fetchFailedMessage)) {
        fetchFailedMessage = i18next.t('LOGIN_PANEL.FRONTEND_ERROR');
      } else if (fetchFailedMessage.includes('must all be from a single asset')) {
        fetchFailedMessage += `\n\n${i18next.t('TABLE_BUILDER.REMOVE_ITEMS_SAME_ASSET')}`;
      } else if (!isAssetError && !isHomogenizeError) {
        // Sometimes we hit errors due to unit mismatches in the table data, so we provide the API error message in
        // addition to our fetch error message to give more information to the user
        apiMessage = fetchFailedMessage;
        fetchFailedMessage = i18next.t('TABLE_BUILDER.FETCH_ERROR');
        checkFailureForEachItem = true;
      }
    }

    if (checkFailureForEachItem && !isPresentationWorkbookMode()) {
      _.forEach(items, (item) => {
        canFetchData(item, sqDurationStore.displayRange, DATA_CANCELLATION_GROUP)
          .then(() => {
            flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id: item.id });
          })
          .catch((e) => catchItemDataFailure(item.id, DATA_CANCELLATION_GROUP, e));
      });
    } else {
      _.forEach(items, (item) => catchItemDataFailure(item.id, DATA_CANCELLATION_GROUP, error));
    }

    flux.dispatch('TABLE_BUILDER_SET_FETCH_FAILED_MESSAGE', {
      fetchFailedMessage,
      mode,
      apiMessage,
    });
  }
}

/**
 * Remove items from the table without removing them from other stores
 * @param items - items to remove
 */
export function removeItemsFromTable(items: any[]) {
  flux.dispatch('TABLE_BUILDER_REMOVE_ITEMS', { items });
}

export const ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.ItemIdColumn,
  columnType: ColumnTypeEnum.UUID,
  columnRules: [{ eventProperty: { propertyName: 'id' } }],
  sortIndex: 0,
  sortAscending: true,
};

export const DATUM_ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.DatumIdColumn,
  columnType: ColumnTypeEnum.TEXT,
  columnRules: [{ eventProperty: { propertyName: '' } }],
};

export const ANCESTOR_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.FormulaAcross.AssetColumn,
  columnType: ColumnTypeEnum.UUID,
  columnRules: [{ ancestor: { columnIndex: 1, level: 1 } }],
};

export const PATH_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.FormulaAcross.PathColumn,
  columnType: ColumnTypeEnum.TEXT,
  columnRules: [{ path: { columnIndex: 1, level: 9999 } }],
};

export const VALUE_UOM_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.FormulaAcross.ValueUomColumn,
  columnType: ColumnTypeEnum.TEXT,
  columnRules: [
    { getItemProperty: { columnIndex: 1, propertyName: SeeqNames.Properties.ValueUom } },
    { getItemProperty: { columnIndex: 1, propertyName: SeeqNames.Properties.Uom } },
  ],
};

export const DATASOURCE_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.FormulaAcross.DatasourceColumn,
  columnType: ColumnTypeEnum.UUID,
  columnRules: [{ datasource: { columnIndexItem: 1 } }],
};

export const DEFAULT_COLUMN_DEFINITION_INPUTS = [
  ID_COLUMN,
  DATUM_ID_COLUMN,
  ANCESTOR_COLUMN,
  PATH_COLUMN,
  VALUE_UOM_COLUMN,
  DATASOURCE_COLUMN,
];

/**
 * Updates the columns in the backing table for the formula across table. Handles both adding and removing columns
 * based on the difference between the existing item finder input and the provided column ids.  If all scaling columns
 * have been removed, the item finder searches are reset but not saved via API.
 *
 * @param columnIds the new array of column ids to be included in the backing table
 * @param tableId the source table id containing the scaling table columns
 * @param fixedItemIds the array of fixed item ids to be included in the backing table
 */
export async function updateColumnsAndItemsInFormulaAcross(
  columnIds: string[],
  tableId: string,
  fixedItemIds: string[],
): Promise<boolean> {
  const itemFinderInput = sqTableBuilderStore.itemFinderInput;
  if (_.isNil(itemFinderInput)) return false;

  const newColumns = columnIds.filter(
    (columnId) =>
      !itemFinderInput.scalingTableUUIDColumnSearches?.some((search) => search.columnDefinitionId === columnId),
  );
  const removedColumns =
    itemFinderInput.scalingTableUUIDColumnSearches
      ?.filter((search) => !columnIds.includes(search.columnDefinitionId))
      ?.map((search) => search.columnDefinitionId) ?? [];

  const newColumnIds = [
    ...(itemFinderInput.scalingTableUUIDColumnSearches
      ?.filter((search) => !removedColumns.includes(search.columnDefinitionId))
      ?.map((search) => search.columnDefinitionId) ?? []),
    ...newColumns,
  ];

  if (newColumnIds.length > 0) {
    await updateBackingTable(newColumnIds, tableId, fixedItemIds);
    return true;
  } else {
    const itemFinderInput: ItemFinderInputV1 = getItemFinderInput(newColumnIds, tableId, []);
    flux.dispatch('TABLE_BUILDER_SET_ITEM_FINDER_INPUT', { itemFinderInput });
    return false;
  }
}

function getItemFinderInput(
  columnIds: string[],
  sourceTableId: string,
  fixedItemIds: string[] = [],
): ItemFinderInputV1 {
  return {
    name: `WorksheetBacking-${sqWorkbenchStore.stateParams.worksheetId}`,
    scalingTableUUIDColumnSearches: columnIds.map((columnId) => ({
      searchType: SearchTypeEnum.MATERIALIZEDTABLEUUIDCOLUMN,
      isInclude: true,
      columnDefinitionId: columnId,
      tableDefinitionId: sourceTableId,
      types: [
        SeeqNames.Types.ThresholdMetric,
        SeeqNames.Types.StoredCondition,
        SeeqNames.Types.StoredSignal,
        SeeqNames.Types.LiteralScalar,
        SeeqNames.Types.CalculatedScalar,
        SeeqNames.Types.CalculatedSignal,
        SeeqNames.Types.CalculatedCondition,
      ],
    })),
    ...(fixedItemIds.length > 0 && {
      fixedListSearches: [
        {
          searchType: SearchTypeEnum.FIXEDLIST,
          isInclude: true,
          itemIds: fixedItemIds,
        },
      ],
    }),
    scopedTo: sqWorkbookStore.workbookId,
  };
}

/**
 * Updates the item finder for the formula across table. Sets isFormulaAcrossTableProcessing to true to indicate
 * that the table is being updated.
 *
 * @param columnIds the new array of column ids to be included in the backing table
 * @param sourceTableId the source table id containing the scaling table columns
 * @param fixedItemIds the array of fixed item ids to be included in the backing table
 */
export async function updateBackingTable(columnIds: string[], sourceTableId: string, fixedItemIds: string[]) {
  flux.dispatch('TABLE_BUILDER_SET_IS_FORMULA_ACROSS_TABLE_PROCESSING', { isFormulaAcrossTableProcessing: true });
  const itemFinderInput: ItemFinderInputV1 = getItemFinderInput(columnIds, sourceTableId, fixedItemIds);
  flux.dispatch('TABLE_BUILDER_SET_ITEM_FINDER_INPUT', { itemFinderInput });
  await sqItemsApi.updateItemFinder(itemFinderInput, { id: sqTableBuilderStore.itemFinderId });
}

/**
 * Adds a backing table for the formula across table. Creates an item finder if it doesn't exist with the provided
 * columns and source table, and creates a table definition if it doesn't exist.
 *
 * @param columnIds the array of column ids to be included in the backing table
 * @param sourceTableId the source table id containing the scaling table columns
 */
export async function addBackingTable(columnIds: string[], sourceTableId: string) {
  const itemFinderInput: ItemFinderInputV1 = getItemFinderInput(columnIds, sourceTableId);
  let itemFinderId = sqTableBuilderStore.itemFinderId;

  if (!itemFinderId) {
    const { data: itemFinderOutput } = await sqItemsApi.createItemFinder({ ...itemFinderInput, enabled: false });
    itemFinderId = itemFinderOutput.id;
    flux.dispatch('TABLE_BUILDER_SET_ITEM_FINDER_INPUT', { itemFinderInput });
    flux.dispatch('TABLE_BUILDER_SET_ITEM_FINDER_ID', { itemFinderId });
  }

  if (!sqTableBuilderStore.tableDefinitionId) {
    const { data: tableDefinitionOutput } = await sqTableDefinitionsApi.createTableDefinition({
      subscriptionId: itemFinderId,
      name: `Backing Table for Worksheet - ${sqWorkbenchStore.stateParams.worksheetId}`,
      description: `Backing Table for Worksheet - ${sqWorkbenchStore.stateParams.worksheetId}`,
      scopedTo: sqWorkbookStore.workbookId,
      batchAction: BatchActionEnum.UPDATEEXISTINGINSERTNEWCLEANUP,
      columnDefinitions: DEFAULT_COLUMN_DEFINITION_INPUTS,
      tableType: TableTypeEnum.FORMULAACROSS,
    });

    flux.dispatch('TABLE_BUILDER_SET_TABLE_DEFINITION_ID', { tableDefinitionId: tableDefinitionOutput.id });
  }
}

/**
 * Removes all items from the details pane (scaling columns), resets the data for simple table, and sets
 * isFormulaAcrossTable and isUsingScalingColumns to false.
 */
export const toggleTableFromScaling = async () => {
  flux.dispatch('TABLE_BUILDER_SET_IS_FORMULA_ACROSS_TABLE', { isFormulaAcrossTable: false });
  await removeItems(sqTrendScalingColumnStore.items);
  flux.dispatch('TABLE_BUILDER_PUSH_FORMULA_ACROSS_SIMPLE_TABLE_DATA', {
    table: { data: [], headers: [] },
  });
  doTrack(TABLE_BUILDER, 'Scaling Button clicked');
  await fetchTable();
};

/**
 * Fetches and dispatches the simple table data that's backed by a FORMULA_ACROSS Materialized Table.
 * @return A promise that resolves when the table has been fetched
 */
export async function fetchFormulaAcrossSimpleTableData(): Promise<void> {
  if (!sqTableBuilderStore.isFormulaAcrossTableProcessing) {
    const viewCapsule = getCapsuleFormula(sqDurationStore.displayRange);
    const formula = `group(${viewCapsule}).toTable('simple')`;

    const statistics: string[] = sqTableBuilderStore.columns
      .filter((column) => column.stat)
      .map((column) => column.stat);

    const itemPropertiesToInclude = sqTableBuilderStore.columns
      .filter((column) => column.backingTableColumn?.includes('item id.properties'))
      .map((column) => column.key);

    const assetPropertiesToInclude = sqTableBuilderStore.columns
      .filter((column) => column.backingTableColumn?.includes('Asset.properties'))
      .map((column) => {
        if (column.key === COLUMNS_AND_STATS.asset.key) {
          return NAME_ITEM_PROPERTY;
        } else {
          return column.key;
        }
      });

    const ancestorPropertiesToInclude = sqTableBuilderStore.columns
      .filter((column) => column.backingTableColumn?.includes('Ancestor.properties'))
      .map((column) => {
        if (column.assetPathId) {
          return NAME_ITEM_PROPERTY;
        } else {
          return column.key;
        }
      });

    const datasourcePropertiesToInclude = sqTableBuilderStore.columns
      .filter((column) => column.backingTableColumn?.includes('Datasource.properties'))
      .map((column) => {
        if (column.key === COLUMNS_AND_STATS.datasourceName.key) {
          return NAME_ITEM_PROPERTY;
        } else {
          return column.key;
        }
      });

    const enabledPaths = sqTableBuilderStore.assetPaths.filter((path) => path.enabled);

    const propertiesToInclude: MaterializedTablePropertyColumnInput[] = [];
    if (itemPropertiesToInclude.length > 0) {
      propertiesToInclude.push({
        uuidColumn: SeeqNames.MaterializedTables.ItemIdColumn,
        propertyNames: [...itemPropertiesToInclude, SeeqNames.Properties.AggregationFunction],
      });
    }
    if (assetPropertiesToInclude.length > 0) {
      propertiesToInclude.push({
        uuidColumn: SeeqNames.MaterializedTables.FormulaAcross.AssetColumn,
        propertyNames: assetPropertiesToInclude,
      });
    }
    if (ancestorPropertiesToInclude.length > 0) {
      enabledPaths.forEach((path) => {
        propertiesToInclude.push({
          uuidColumn: `${ANCESTOR_COLUMN_NAME} ${path.value}`,
          propertyNames: assetPropertiesToInclude,
        });
      });
    }

    if (datasourcePropertiesToInclude.length > 0) {
      propertiesToInclude.push({
        uuidColumn: SeeqNames.MaterializedTables.FormulaAcross.DatasourceColumn,
        propertyNames: datasourcePropertiesToInclude,
      });
    }

    const columnsToInclude: string[] = [
      SeeqNames.MaterializedTables.ItemIdColumn,
      SeeqNames.MaterializedTables.FormulaAcross.AssetColumn,
      SeeqNames.MaterializedTables.FormulaAcross.PathColumn,
      SeeqNames.MaterializedTables.FormulaAcross.ValueUomColumn,
      SeeqNames.MaterializedTables.FormulaAcross.DatasourceColumn,
    ];
    enabledPaths.forEach((path) => columnsToInclude.push(`${ANCESTOR_COLUMN_NAME} ${path.value}`));

    const tableFormulas: MaterializedTableFormulaInput[] = [
      {
        formula,
        parameters: ['column_item=item id'],
        statistics: [...statistics, 'metricValue()'],
      },
    ];

    const response = await fetchMaterializedTable({
      tableDefinitionId: sqTableBuilderStore.tableDefinitionId,
      columnsToInclude,
      propertiesToInclude,
      includeOverrides: false,
      tableFormulas,
    });
    if (response.errors) {
      errorToast({ messageKey: 'TABLE_BUILDER.NO_VALID_TABLE_DATA_FOUND' });
    }

    flux.dispatch('TABLE_BUILDER_PUSH_FORMULA_ACROSS_SIMPLE_TABLE_DATA', {
      table: { data: response.data?.table?.rows ?? [], headers: response.data?.table?.headers ?? [] },
    });
  }
}

/**
 * Updates the item finder for the formula across table when scalingColumnUpdate and isTableSubscribed are true.
 * If no scaling columns remain, sets isFormulaAcrossTable to false and resets the simple table data.
 *
 * @param scalingColumnsUpdated - true if scaling columns have been updated
 * @param isTableSubscribed - indicates if the table is subscribed to for updates, if true, the item finder will be
 *   updated
 * @param fixedItemsIds - set of fixed item ids that should be included in the item finder
 * @param fixedItemIdsChanged - true if fixedItemIds have changed
 */
export async function updateItemFinderContents(
  scalingColumnsUpdated: boolean,
  isTableSubscribed: boolean,
  fixedItemsIds: string[],
  fixedItemIdsChanged: boolean,
) {
  if (isTableSubscribed && (scalingColumnsUpdated || fixedItemIdsChanged)) {
    const scalingColumns = sqTrendScalingColumnStore.items;
    const remainingColumns = await exposedForTesting.updateColumnsAndItemsInFormulaAcross(
      scalingColumns.map((column) => column.id),
      scalingColumns[0]?.tableId ?? '',
      fixedItemsIds,
    );
    if (!remainingColumns) {
      flux.dispatch('TABLE_BUILDER_SET_IS_FORMULA_ACROSS_TABLE', { isFormulaAcrossTable: false });
      flux.dispatch('TABLE_BUILDER_PUSH_FORMULA_ACROSS_SIMPLE_TABLE_DATA', {
        table: { data: [], headers: [] },
      });
    }
    if (scalingColumnsUpdated) {
      flux.dispatch('TREND_SET_SCALING_COLUMNS_UPDATED', false);
    }
  }
}

export const exposedForTesting = {
  setChartViewSettings,
  setShowChartView,
  fetchTable,
  setHomogenizeUnits,
  setAssetId,
  fetchOldMetrics,
  fetchConditionTableData,
  fetchSimpleTableData,
  catchTableBuilderDataFailure,
  fetchSimpleTableDistinctStringValues,
  setIsMigrating,
  setMode,
  computeColorForOldMetric,
  removeOldMetric,
  getThresholds,
  getCorrectWarningLogs,
  setHeadersType,
  getName,
  removeColumn,
  toggleColumn,
  addTextColumn,
  moveColumn,
  setColumnBackground,
  setColumnTextAlign,
  setColumnTextColor,
  setColumnTextStyle,
  setHeaderBackground,
  setHeaderTextAlign,
  setHeaderTextColor,
  setHeaderTextStyle,
  setStyleToAllColumns,
  setStyleToAllHeaders,
  setStyleToAllHeadersAndColumns,
  copyStyle,
  pasteStyleOnHeader,
  pasteStyleOnColumn,
  pasteStyleOnHeaderAndColumn,
  setCellText,
  setHeaderText,
  setHeadersFormat,
  setHeadersProperty,
  setIsTableStriped,
  setColumnFilter,
  fetchFormulaAcrossSimpleTableData,
  updateColumnsAndItemsInFormulaAcross,
};
